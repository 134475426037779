<template>
  <div id="footer" class="footer-container">
    <div class="footer active " :class="{ 'fixedjia': fixedzindex }">
      <img src="@/assets/imgs/12-04.png" />
      <div class="desc">
        Beat Teddy is operated by StarDust Technology B.V. and all payments are processed by StarDust Technology B.V.
        The games and prizes offered at Beat Teddy are operated by StarDust Technology B.V. and are regulated by the
        Curaçao Gaming Board, licence no. 5536/JAZ/44268. The registered address of StarDust Technology B.V. is Pos
        Cabai Office Park 5, Willemstad, Curaçao. 18+ Play responsibly: <a style="color:#0049FF"
          href="https://www.gamingaddictsanonymous.org/">https://www.gamingaddictsanonymous.org/</a>
      </div>
      <div class="name">
        <router-link to="/about/terms">TERMS AND CONDITIONS</router-link>
        <router-link to="/about/private">PRIVACY Policy</router-link>
        <router-link to="/about/gaming">Responsible Gaming</router-link>
        <router-link to="/about/security">Security</router-link>
        <router-link to="/about/gdpr">GDPR Compliance Policy</router-link>
        <router-link to="/about/policy">Underage Gaming Policy</router-link>
      </div>
      <div class="title">©2024 BEATTEDDY</div>
    </div>
    <div class="footer act">
      <div class="name">
        <router-link to="/about/terms">TERMS AND CONDITIONS</router-link>
        <router-link to="/about/private">PRIVACY Policy</router-link>
        <router-link to="/about/gaming">Responsible Gaming</router-link>
        <router-link to="/about/security">Security</router-link>
        <router-link to="/about/gdpr">GDPR Compliance Policy</router-link>
        <router-link to="/about/policy">Underage Gaming Policy</router-link>
      </div>
      <img src="@/assets/imgs/12-04.png" />
      <div class="desc">
        Beat Teddy is operated by StarDust Technology B.V. and all payments are processed by StarDust Technology B.V.
        The games and prizes offered at Beat Teddy are operated by StarDust Technology B.V. and are regulated by the
        Curaçao Gaming Board, licence no. 5536/JAZ/44268. The registered address of StarDust Technology B.V. is Pos
        Cabai Office Park 5, Willemstad, Curaçao. 18+ Play responsibly: <a style="color:#0049FF"
          href="https://www.gamingaddictsanonymous.org/">https://www.gamingaddictsanonymous.org/</a>
      </div>
      <div class="title">©2024 BEATTEDDY</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footerPage',
  props: {
    fixedzindex: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showFixed: false
    }
  },
  created() { },
  mounted() {
    if (localStorage.getItem('token')) {
      // if (this.$store.state.token) {
      // console.log(new Date(this.$store.state.UserInformation.refresh_token_expires_at), new Date(), new Date(), new Date(this.$store.state.UserInformation.refresh_token_expires_at) - 1 * 60 * 60 * 1000)
      // console.log(+new Date(this.$store.state.UserInformation.refresh_token_expires_at) > +new Date(), +new Date() > +new Date(this.$store.state.UserInformation.refresh_token_expires_at) - 1 * 60 * 60 * 1000)
      // console.log('奔波',+new Date(this.$store.getters.getRefreshTokenExpiresAt) > +new Date(), +new Date() > +new Date(this.$store.getters.getRefreshTokenExpiresAt) - 1 * 60 * 60 * 1000)
      // console.log(+new Date(this.$store.state.UserInformation.refresh_token_expires_at) > +new Date() && +new Date() > +new Date(this.$store.state.UserInformation.refresh_token_expires_at) - 1 * 60 * 60 * 1000)
      // if (+new Date(this.$store.state.UserInformation.refresh_token_expires_at) > +new Date() && +new Date() > +new Date(this.$store.state.UserInformation.refresh_token_expires_at) - 1 * 60 * 60 * 1000) {
      if (+new Date(this.$store.getters.getRefreshTokenExpiresAt) > +new Date() && +new Date() > +new Date(this.$store.getters.getRefreshTokenExpiresAt) - 1 * 60 * 60 * 1000) {
        // 请求更新接口
        this.$http.post('/token/refresh/', {
          refresh: this.$store.state.UserInformation.refresh
        }).then(res => {
          if (res.code === 2000) {
            const UserInformation = JSON.parse(localStorage.getItem('UserInformation'))
            UserInformation.access = res.data.access
            UserInformation.refresh = res.data.refresh
            localStorage.setItem('UserInformation', JSON.stringify(UserInformation))
            localStorage.setItem('token', res.data.access)
            this.$store.commit('upToken', res.data.access)
            this.$store.commit('upAccess', res.data.access)
            this.$store.commit('upRefresh', res.data.refresh)
            this.$store.commit('upRefreshTokenExpiresAt', 22 * 60 * 60 * 1000 + +new Date())
            // this.$store.state.UserInformation.access = res.data.access
            // this.$store.state.UserInformation.refresh = res.data.refresh
            // this.$store.state.UserInformation.refresh_token_expires_at = 22 * 60 * 60 * 1000 + +new Date()
          } else {
            this.$router.push('/login')
            // this.$message.error(res.msg)
          }
        })
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  background: #1a1a1a;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // padding: 0 227px 0 30px;
  padding: 28px 0px 20px;
  font-size: 9px;

  img {
    width: 352px;
    margin: 0px auto 20px;
  }

  .desc {
    width: 90%;
    text-align: center;
    font-size: 12px;
    color: #ffffff;
    font-family: "FuturaPTBook";
    margin: 0px auto;
  }

  .title {
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: center;
    font-family: "FuturaPTBold";
    font-size: 12px;
  }

  .name {
    font-family: "Helvetica";
    text-transform: uppercase;

    a {
      color: #fff;
      padding: 0 5px;
      border-left: 1px solid #fff;
      letter-spacing: 1px;

      &:nth-child(1) {
        border-left: none;
      }
    }
  }
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .footer {
    // padding-right: 50px;
    // height: 400px;
    padding: 28px 17px 0;
    height: auto;

    &.active {
      display: none;
    }

    &.act {
      display: block;
    }

    img {
      margin-top: 20px;
    }

    .name a {
      display: block;
      text-align: center;
      border-left: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      // line-height: 50px;
      padding: 13px 0 11px;
    }

    .title {
      // line-height: 36px;
      text-align: center;
      // padding: 40px;
      padding: 35px 0 33px 0;
    }
  }
}

@media screen and (min-width: 1000px) {
  .footer {
    // position: fixed;
    // left: 0;
    // bottom: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .footer {
    // padding-right: 50px;
    // height: 400px;
    height: auto;

    &.active {
      display: none;
    }

    &.act {
      display: block;
    }

    .name a {
      display: block;
      text-align: center;
      border-left: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      // line-height: 50px;
      padding: 13px 0 11px;
    }

    .title {
      // line-height: 36px;
      text-align: center;
      // padding: 40px;
      padding: 35px 0 33px 0;
    }
  }
}

@media screen and (min-width: 1000px) and (max-width: 1700px) {
  .footer {

    // padding-right: 50px;
    &.active {
      display: block;

      .name {
        display: flex;
        justify-content: center;
        padding: 18px 0 18px;
      }
    }

    &.act {
      display: none;
    }
  }
}

@media screen and (min-width: 1700px) {
  .footer {
    padding-right: 100px;

    &.active {
      display: block;

      .name {
        display: flex;
        justify-content: center;
        padding: 18px 0 18px;
      }
    }

    &.act {
      display: none;
    }
  }
}

.fixedjia {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
}
</style>
