import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const UserInformation =
  JSON.parse(localStorage.getItem("UserInformation")) || {};
const access = localStorage.getItem("access") || null;
const refresh = localStorage.getItem("refresh") || null;
const refresh_token_expires_at =
  localStorage.getItem("refresh_token_expires_at") || null;
const UserInfo = JSON.parse(localStorage.getItem("UserInfo")) || {};
const token = localStorage.getItem("token") || null;

export default new Vuex.Store({
  state: {
    UserInformation: UserInformation,
    access: access,
    refresh: refresh,
    refresh_token_expires_at: refresh_token_expires_at,
    UserInfo: UserInfo,
    token: token,
    jackpotTooltime: 0,
  },
  getters: {
    getAccess(state) {
      return state.access;
    },
    getRefresh(state) {
      return state.refresh;
    },
    getRefreshTokenExpiresAt(state) {
      return state.refresh_token_expires_at;
    },
    getUserInformation(state) {
      return state.UserInformation;
    },
    getToken(state) {
      return state.token;
    },
  },
  mutations: {
    jackpotTooltime(state, payload) {
      state.jackpotTooltime = payload;
    },
    upUserInformation(state, val) {
      localStorage.setItem("UserInformation", val);
      state.UserInformation = val;
    },
    upAccess(state, val) {
      localStorage.setItem("access", val);
      state.access = val;
    },
    upRefresh(state, val) {
      localStorage.setItem("refresh", val);
      state.refresh = val;
    },
    upRefreshTokenExpiresAt(state, val) {
      localStorage.setItem("refresh_token_expires_at", val);
      state.refresh_token_expires_at = val;
    },
    upToken(state, val) {
      localStorage.setItem("token", val);
      state.token = val;
    },
    updateRestMinutes(state, payload) {
      state.restMinutes = payload;
    },
  },
  actions: {
    async fetchJackpotTooltime({ commit }) {
      const date = new Date().getTime();
      const res = await axios.get(
        "/api/game/jackpot/tool/time/?timestamp=" + date
      );
      if (res.code === 2000) {
        let dateTime =
          res.data.time_stamp +
          res.data.day * 24 * 60 * 60 * 1000 +
          res.data.hour * 60 * 60 * 1000 +
          res.data.minute * 60 * 1000 +
          res.data.second * 1000;

        let restTime =
          res.data.day * 24 * 60 * 60 * 1000 +
          res.data.hour * 60 * 60 * 1000 +
          res.data.minute * 60 * 1000 +
          res.data.second * 1000;

        let date = new Date(dateTime);
        let restMinutes = Math.floor(restTime / (1000 * 60));

        commit("updateRestMinutes", restMinutes);
        commit("jackpotTooltime", date);
      }
    },
    async postWinnersUpd({ commit }, id) {
      const time = String(new Date().getTime());
      const newTime = time.substr(0, 10) + "." + time.substr(10, 13);
      let data = {
        winning_id: id,
        game_client: "jackpot",
        time_stamp: newTime,
      };
      const sign = md5("{}" + newTime + "fa5cfc4f4366dbca2a535828163ffe91");
      data.sign = sign;
      const res = await axios.post("/api/game/jackpot/winners/upd/", data);
      if (res.code === 2000) {
      }
    },
  },
  modules: {},
});
