const areas = [
  { name: 'Albania', code: '355' },
  { name: 'Angola', code: '244' },
  { name: 'Argentina', code: '54' },
  { name: 'Australia', code: '61' },
  { name: 'Austria', code: '43' },
  { name: 'Azerbaijan', code: '994' },
  { name: 'Bahamas', code: '1242' },
  { name: 'Bahrain', code: '973' },
  { name: 'Belarus', code: '375' },
  { name: 'Belgium', code: '32' },
  { name: 'Belize', code: '501' },
  { name: 'Benin', code: '229' },
  { name: 'Bolivia', code: '591' },
  { name: 'Brazil', code: '55' },
  { name: 'Brunei', code: '673' },
  { name: 'Bulgaria', code: '359' },
  { name: 'Burkina Faso', code: '226' },
  { name: 'Cambodia', code: '855' },
  { name: 'Cameroon', code: '237' },
  { name: 'Canada', code: '1' },
  { name: 'Cape Verde', code: '238' },
  { name: 'Cayman Islands', code: '1345' },
  { name: 'Chad', code: '235' },
  { name: 'Chile', code: '56' },
  { name: 'Colombia', code: '57' },
  { name: 'Comoros', code: '269' },
  { name: 'Costa Rica', code: '506' },
  { name: 'Croatia', code: '385' },
  { name: 'Cyprus', code: '357' },
  { name: 'Denmark', code: '45' },
  { name: 'Djibouti', code: '253' },
  { name: 'Egypt', code: '20' },
  { name: 'El Salvador', code: '503' },
  { name: 'Equatorial Guinea', code: '240' },
  { name: 'Estonia', code: '372' },
  { name: 'Finland', code: '358' },
  { name: 'France', code: '33' },
  { name: 'Gabon', code: '241' },
  { name: 'Gambia', code: '220' },
  { name: 'Georgia', code: '995' },
  { name: 'Germany', code: '49' },
  { name: 'Greece', code: '30' },
  { name: 'Grenada', code: '1473' },
  { name: 'Guatemala', code: '502' },
  { name: 'Guinea', code: '224' },
  { name: 'Guinea-Bissau', code: '245' },
  { name: 'Guyana', code: '592' },
  { name: 'Holland', code: '31' },
  { name: 'Honduras', code: '504' },
  { name: 'Hong Kong(China)', code: '852' },
  { name: 'Hungary', code: '36' },
  { name: 'India', code: '91' },
  { name: 'Indonesia', code: '62' },
  { name: 'Ireland', code: '353' },
  { name: 'Israel', code: '972' },
  { name: 'Italy', code: '39' },
  { name: 'Jamaica', code: '1876' },
  { name: 'Japan', code: '81' },
  { name: 'Jordan', code: '962' },
  { name: 'Kenya', code: '254' },
  { name: 'Korea', code: '82' },
  { name: 'Kuwait', code: '965' },
  { name: 'Kyrgyzstan', code: '996' },
  { name: 'Latvia', code: '371' },
  { name: 'Lesotho', code: '266' },
  { name: 'Lithuania', code: '370' },
  { name: 'Luxembourg', code: '352' },
  { name: 'Macao(China)', code: '853' },
  { name: 'Madagascar', code: '261' },
  { name: 'Mainland China', code: '86' },
  { name: 'Malawi', code: '265' },
  { name: 'Malaysia', code: '60' },
  { name: 'Maldives', code: '960' },
  { name: 'Mali', code: '223' },
  { name: 'Mauritania', code: '222' },
  { name: 'Mauritius', code: '230' },
  { name: 'Mexico', code: '52' },
  { name: 'Moldova', code: '373' },
  { name: 'Mongolia', code: '976' },
  { name: 'Morocco', code: '212' },
  { name: 'Mozambique', code: '258' },
  { name: 'Namibia', code: '264' },
  { name: 'New Zealand', code: '64' },
  { name: 'Nicaragua', code: '505' },
  { name: 'Niger', code: '227' },
  { name: 'Nigeria', code: '234' },
  { name: 'Norway', code: '47' },
  { name: 'Oman', code: '968' },
  { name: 'Palestine', code: '970' },
  { name: 'Panama', code: '507' },
  { name: 'Papua New Guinea', code: '675' },
  { name: 'Peru', code: '51' },
  { name: 'Philippines', code: '63' },
  { name: 'Poland', code: '48' },
  { name: 'Portugal', code: '351' },
  { name: 'Qatar', code: '974' },
  { name: 'Romania', code: '40' },
  { name: 'Russia', code: '7' },
  { name: 'Rwanda', code: '250' },
  { name: 'Saudi Arabia', code: '966' },
  { name: 'Senegal', code: '221' },
  { name: 'Serbia', code: '381' },
  { name: 'Seychelles', code: '248' },
  { name: 'Sierra Leone', code: '232' },
  { name: 'Singapore', code: '65' },
  { name: 'Slovakia', code: '421' },
  { name: 'Slovenia', code: '386' },
  { name: 'South Africa', code: '27' },
  { name: 'Spain', code: '34' },
  { name: 'Sri Lanka', code: '94' },
  { name: 'Suriname', code: '597' },
  { name: 'Swaziland', code: '268' },
  { name: 'Sweden', code: '46' },
  { name: 'Switzerland', code: '41' },
  { name: 'Taiwan(China)', code: '886' },
  { name: 'Tajikistan', code: '992' },
  { name: 'Tanzania', code: '255' },
  { name: 'Thailand', code: '66' },
  { name: 'Togo', code: '228' },
  { name: 'Trinidad & Tobago', code: '1868' },
  { name: 'Tunisia', code: '216' },
  { name: 'Turkey', code: '90' },
  { name: 'Turkmenistan', code: '993' },
  { name: 'Uganda', code: '256' },
  { name: 'Ukraine', code: '380' },
  { name: 'United Arab Emirates', code: '971' },
  { name: 'United Kingdom', code: '44' },
  { name: 'United States of America', code: '1' },
  { name: 'Uruguay', code: '598' },
  { name: 'Uzbekistan', code: '998' },
  { name: 'Venezuela', code: '58' },
  { name: 'Vietnam', code: '84' },
  { name: 'Virgin Islands, British', code: '1284' },
  { name: 'Yemen', code: '967' },
  { name: 'Zambia', code: '260' },
  { name: 'Zimbabwe', code: '263' }
]

export default areas
