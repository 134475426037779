import Vue from "vue";
import VueRouter from "vue-router";
import Intercom from "@intercom/messenger-js-sdk";
import crypto from "crypto-js";

Vue.use(VueRouter);

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  // 首页
  { path: "/", component: () => import("@/views/Home/index.vue") },
  { path: "/time", component: () => import("@/views/Home/time.vue") },
  {
    path: "/editavatar",
    component: () => import("@/views/Home/editavatar.vue"),
  },
  { path: "/movebox", component: () => import("@/views/Home/movebox.vue") },

  // 登录
  { path: "/login", component: () => import("@/views/Login/index.vue") },
  // 忘记密码
  { path: "/reset-password", component: () => import("@/views/Login/Pwd.vue") },
  { path: "/newpwd", component: () => import("@/views/Login/Newpwd.vue") },
  // 发送邮箱
  { path: "/email", component: () => import("@/views/Login/Email.vue") },
  // 注册
  { path: "/register", component: () => import("@/views/Login/Reg.vue") },
  // 注册下一步 reguser
  {
    path: "/register-info",
    component: () => import("@/views/Login/RegUser.vue"),
    meta: {
      keepAlive: true, // 需要缓存false//不需要缓存
    },
  },
  // 注册成功页面
  {
    path: "/successful",
    component: () => import("@/views/Login/Successful.vue"),
  },
  // 临时替代 告知已经发送注册邮件的通知页面
  { path: "/welcome", component: () => import("@/views/Welcome/welcome.vue") },
  { path: "/expired", component: () => import("@/views/Welcome/expired.vue") },
  // 推荐码
  { path: "/referral", component: () => import("@/views/Login/Referral.vue") },
  // 注册邮箱页面
  { path: "/regemail", component: () => import("@/views/Login/RegEmail.vue") },

  // 注册邮箱页面
  { path: "/about/gdpr", component: () => import("@/views/About/gdpr.vue") },
  {
    path: "/about/policy",
    component: () => import("@/views/About/Policy.vue"),
  },
  {
    path: "/about/gaming",
    component: () => import("@/views/About/Gaming.vue"),
  },
  {
    path: "/about/private",
    component: () => import("@/views/About/Private.vue"),
  },
  {
    path: "/about/security",
    component: () => import("@/views/About/Security.vue"),
  },
  { path: "/about/terms", component: () => import("@/views/About/Terms.vue") },
  // 个人中心
  { path: "/gameindex", component: () => import("@/views/User/gameindex.vue") },
  { path: "/profile", component: () => import("@/views/User/my.vue") },
  { path: "/gameplay", component: () => import("@/views/User/gameplay.vue") },
  {
    path: "/transaction-history",
    component: () => import("@/views/User/transaction.vue"),
  },
  { path: "/wallet", component: () => import("@/views/User/Wallet.vue") },
  { path: "/upload", component: () => import("@/views/upload.vue") },
  // {
  //   path: "/my-tickets",
  //   component: () => import("@/views/User/RaffleTicket.vue"),
  // },
  {
    path: "/my-tickets",
    component: () => import("@/views/User/newRaffleTicket.vue"),
  },
  {
    path: "/rule/rule",
    component: () => import("@/views/Iframe/rule/rule.vue"),
  },
  { path: "/rule/fun", component: () => import("@/views/Iframe/rule/fun.vue") },
  {
    path: "/rule/points",
    component: () => import("@/views/Iframe/rule/points.vue"),
  },
  {
    path: "/rule/settings",
    component: () => import("@/views/Iframe/rule/settings.vue"),
  },
  {
    path: "/rules/rule",
    component: () => import("@/views/Iframe/blackjack/rules.vue"),
  },
  {
    path: "/rules/options",
    component: () => import("@/views/Iframe/blackjack/options.vue"),
  },
  {
    path: "/rules/settings",
    component: () => import("@/views/Iframe/blackjack/settings.vue"),
  },
  // 游戏
  // { path: "/jackpot", component: () => import("@/views/Game/jackpot.vue") },
  // { path: "/success", component: () => import("@/views/Game/success.vue") },
  // { path: "/winners", component: () => import("@/views/Game/winners.vue") },
  // { path: "/tickets", component: () => import("@/views/Game/tickets.vue") },
];

const router = new VueRouter({
  routes,
  mode: "history", //去除#号
  // 每次切换滚动到头部
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.afterEach(() => {
  let params = {
    app_id: "kfcrihp6",
  };
  const userInfo = JSON.parse(localStorage.getItem("UserInfo"));
  if (userInfo) {
    const secretKey = "SQmJpq7nFK8xSvOiyujVbyOvH1q9yrlZe8G4oe-_";
    const userIdentifier = userInfo.id.toString();
    let data = crypto.HmacSHA256(userIdentifier, secretKey);
    const hash = crypto.enc.Hex.stringify(data);
    params = {
      app_id: "kfcrihp6",
      user_id: userInfo.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
      name: userInfo.username, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
      email: userInfo.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      user_hash: hash,
    };
  } else {
    params = {
      app_id: "kfcrihp6",
    };
  }
  Intercom(params);
});

export default router;
