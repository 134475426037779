const util = {}
/** 验证邮箱 */
util.validateEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)

/** 验证密码 */
// 8位～ 数字 字母 + 特殊符号～
util.validatePwd = (pwd) => /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/.test(pwd)
// /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_-])[A-Za-z\d!@#$%^&*()_-]{8,}$/
// 特殊符号仅限数字符号1-0 上的的哈
// /^(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}$/
// /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_-])[A-Za-z\d!@#$%^&*()_-]{8,}$/
// 验证code码
util.validCode = (input) => /^[a-zA-Z0-9]+$/.test(input)

// 验证Tether账户只包含字母数字
util.validTetherAcco = (input) => /^[a-zA-Z0-9].{3,}$/.test(input)

// 验证venmo账户只包含字母数字特殊符号_-
util.validVenmoAcco = (input) => /^[0-9a-zA-Z(\d+(\_\d+)].{5,}$/.test(input)

/** 验证中奖彩票输入内容 */
// 仅由字母组成的有效组合，每个字母只能使用一次
util.validTickets = (input) => /^(?!.*(.).*\1)[a-zA-Z]+$/.test(input)

/** 验证手机号 */
util.validZellePhone = (phone) => /^[^0]\d{4,}$/.test(phone) 

/** date format */
util.formatDate = (date)=>{
  // Format the date part (e.g., 'March 29 2024')
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
  const datePart = dateFormatter.format(date);
  return datePart
}

/** money format */
util.formatNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

/** 验证手机号 */
util.validatePhone = (phone) => /^(\+86){0,1}1\d{10}$/.test(phone) ||
  /^(\+886){1}0{0,1}[6,7,9](?:\d{7}|\d{8}|\d{10})$/.test(phone) ||
  /^(\+852){1}0{0,1}[1,5,6,9](?:\d{7}|\d{8}|\d{12})$/.test(phone) ||
  /^(\+60){1}1\d{8,9}$/.test(phone) ||
  /^(\+65){1}[13689]\d{6,9}$/.test(phone) ||
  /^(\+81){1}0{0,1}[7,8,9](?:\d{8}|\d{9})$/.test(phone) ||
  /^(\+82){1}0{0,1}[7,1](?:\d{8}|\d{9})$/.test(phone) ||
  /^(\+1){1}\d{10,12}$/.test(phone) ||
  /^(\+1){1}\d{10}/.test(phone) ||
  /^(\+61){1}4\d{8,9}$/.test(phone) ||
  /^(\+64){1}[278]\d{7,9}$/.test(phone) ||
  /^(\+355){1}\d{6,15}$/.test(phone) ||
  /^(\+244){1}\d{6,15}$/.test(phone) ||
  /^(\+54){1}\d{6,12}$/.test(phone) ||
  /^(\+43){1}\d{6,12}$/.test(phone) ||
  /^(\+994){1}\d{6,15}$/.test(phone) ||
  /^(\+1242){1}\d{6,12}$/.test(phone) ||
  /^(\+973){1}\d{6,15}$/.test(phone) ||
  /^(\+375){1}\d{6,12}$/.test(phone) ||
  /^(\+32){1}\d{6,12}$/.test(phone) ||
  /^(\+501){1}\d{6,12}$/.test(phone) ||
  /^(\+229){1}\d{6,15}$/.test(phone) ||
  /^(\+591){1}\d{6,15}$/.test(phone) ||
  /^(\+55){1}\d{6,12}$/.test(phone) ||
  /^(\+673){1}\d{6,15}$/.test(phone) ||
  /^(\+359){1}\d{6,12}$/.test(phone) ||
  /^(\+226){1}\d{6,15}$/.test(phone) ||
  /^(\+855){1}\d{6,12}$/.test(phone) ||
  /^(\+237){1}\d{6,15}$/.test(phone) ||
  /^(\+238){1}\d{6,15}$/.test(phone) ||
  /^(\+1345){1}\d{6,15}$/.test(phone) ||
  /^(\+235){1}\d{6,15}$/.test(phone) ||
  /^(\+56){1}\d{6,12}$/.test(phone) ||
  /^(\+57){1}\d{6,12}$/.test(phone) ||
  /^(\+269){1}\d{6,15}$/.test(phone) ||
  /^(\+506){1}\d{6,15}$/.test(phone) ||
  /^(\+385){1}\d{6,15}$/.test(phone) ||
  /^(\+357){1}\d{6,15}$/.test(phone) ||
  /^(\+45){1}\d{6,12}$/.test(phone) ||
  /^(\+253){1}\d{6,15}$/.test(phone) ||
  /^(\+20){1}\d{6,12}$/.test(phone) ||
  /^(\+503){1}\d{6,15}$/.test(phone) ||
  /^(\+240){1}\d{6,15}$/.test(phone) ||
  /^(\+372){1}\d{6,12}$/.test(phone) ||
  /^(\+358){1}\d{6,12}$/.test(phone) ||
  /^(\+33){1}(\d{6}|\d{8,9})$/.test(phone) ||
  /^(\+241){1}\d{6,15}$/.test(phone) ||
  /^(\+220){1}\d{6,15}$/.test(phone) ||
  /^(\+995){1}\d{6,15}$/.test(phone) ||
  /^(\+49){1}1(\d{5,6}|\d{9,12})$/.test(phone) ||
  /^(\+30){1}\d{6,12}$/.test(phone) ||
  /^(\+1473){1}\d{6,15}$/.test(phone) ||
  /^(\+502){1}\d{6,15}$/.test(phone) ||
  /^(\+224){1}\d{6,15}$/.test(phone) ||
  /^(\+245){1}\d{6,15}$/.test(phone) ||
  /^(\+592){1}\d{6,15}$/.test(phone) ||
  /^(\+504){1}\d{6,15}$/.test(phone) ||
  /^(\+36){1}\d{6,12}$/.test(phone) ||
  /^(\+91){1}\d{6,12}$/.test(phone) ||
  /^(\+62){1}[2-9]\d{7,11}$/.test(phone) ||
  /^(\+353){1}\d{6,12}$/.test(phone) ||
  /^(\+972){1}\d{6,12}$/.test(phone) ||
  /^(\+39){1}[37]\d{8,11}$/.test(phone) ||
  /^(\+1876){1}\d{6,15}$/.test(phone) ||
  /^(\+962){1}\d{6,12}$/.test(phone) ||
  /^(\+254){1}\d{6,15}$/.test(phone) ||
  /^(\+965){1}\d{6,15}$/.test(phone) ||
  /^(\+996){1}\d{6,12}$/.test(phone) ||
  /^(\+371){1}\d{6,15}$/.test(phone) ||
  /^(\+266){1}\d{6,15}$/.test(phone) ||
  /^(\+370){1}\d{6,12}$/.test(phone) ||
  /^(\+352){1}\d{6,12}$/.test(phone) ||
  /^(\+853){1}6\d{7}/.test(phone) ||
  /^(\+261){1}\d{6,15}$/.test(phone) ||
  /^(\+265){1}\d{6,15}$/.test(phone) ||
  /^(\+960){1}\d{6,12}$/.test(phone) ||
  /^(\+223){1}\d{6,15}$/.test(phone) ||
  /^(\+222){1}\d{6,15}$/.test(phone) ||
  /^(\+230){1}\d{6,15}$/.test(phone) ||
  /^(\+52){1}\d{6,12}$/.test(phone) ||
  /^(\+373){1}\d{6,15}$/.test(phone) ||
  /^(\+976){1}\d{6,12}$/.test(phone) ||
  /^(\+212){1}\d{6,12}$/.test(phone) ||
  /^(\+258){1}\d{6,15}$/.test(phone) ||
  /^(\+264){1}\d{6,15}$/.test(phone) ||
  /^(\+31){1}6\d{8}/.test(phone) ||
  /^(\+505){1}\d{6,15}$/.test(phone) ||
  /^(\+227){1}\d{6,15}$/.test(phone) ||
  /^(\+234){1}\d{6,12}$/.test(phone) ||
  /^(\+47){1}\d{6,12}$/.test(phone) ||
  /^(\+968){1}\d{6,15}$/.test(phone) ||
  /^(\+970){1}\d{6,15}$/.test(phone) ||
  /^(\+507){1}\d{6,12}$/.test(phone) ||
  /^(\+675){1}\d{6,15}$/.test(phone) ||
  /^(\+51){1}\d{6,12}$/.test(phone) ||
  /^(\+63){1}[24579](\d{7,9}|\d{12})$/.test(phone) ||
  /^(\+48){1}\d{6,12}$/.test(phone) ||
  /^(\+351){1}\d{6,12}$/.test(phone) ||
  /^(\+974){1}\d{6,12}$/.test(phone) ||
  /^(\+40){1}\d{6,12}$/.test(phone) ||
  /^(\+7){1}[13489]\d{9,11}$/.test(phone) ||
  /^(\+250){1}\d{6,15}$/.test(phone) ||
  /^(\+966){1}\d{6,12}$/.test(phone) ||
  /^(\+221){1}\d{6,15}$/.test(phone) ||
  /^(\+381){1}\d{6,12}$/.test(phone) ||
  /^(\+248){1}\d{6,12}$/.test(phone) ||
  /^(\+232){1}\d{6,15}$/.test(phone) ||
  /^(\+421){1}\d{6,15}$/.test(phone) ||
  /^(\+386){1}\d{6,15}$/.test(phone) ||
  /^(\+27){1}\d{6,12}$/.test(phone) ||
  /^(\+34){1}\d{6,12}$/.test(phone) ||
  /^(\+94){1}\d{6,12}$/.test(phone) ||
  /^(\+597){1}\d{6,15}$/.test(phone) ||
  /^(\+268){1}\d{6,15}$/.test(phone) ||
  /^(\+46){1}[124-7](\d{8}|\d{10}|\d{12})/.test(phone) ||
  /^(\+41){1}\d{6,12}$/.test(phone) ||
  /^(\+992){1}\d{6,15}$/.test(phone) ||
  /^(\+255){1}\d{6,15}$/.test(phone) ||
  /^(\+66){1}[13456789]\d{7,8}$/.test(phone) ||
  /^(\+228){1}\d{6,15}$/.test(phone) ||
  /^(\+1868){1}\d{6,15}$/.test(phone) ||
  /^(\+216){1}\d{6,12}$/.test(phone) ||
  /^(\+90){1}\d{6,12}$/.test(phone) ||
  /^(\+993){1}\d{6,15}$/.test(phone) ||
  /^(\+256){1}\d{6,15}$/.test(phone) ||
  /^(\+380){1}[3-79]\d{8,9}$/.test(phone) ||
  /^(\+971){1}\d{6,12}$/.test(phone) ||
  /^(\+44){1}[347-9](\d{8,9}|\d{11,12})$/.test(phone) ||
  /^(\+598){1}\d{6,15}$/.test(phone) ||
  /^(\+998){1}\d{6,15}$/.test(phone) ||
  /^(\+58){1}\d{6,12}$/.test(phone) ||
  /^(\+84){1}[1-9]\d{6,9}$/.test(phone) ||
  /^(\+1284){1}\d{6,12}$/.test(phone) ||
  /^(\+967){1}\d{6,15}$/.test(phone) ||
  /^(\+260){1}\d{6,15}$/.test(phone) ||
  /^(\+263){1}\d{6,15}$/.test(phone)

export default util
