// 自定义插件模块
import axios from "axios";
import { Message } from "element-ui";
import router from "@/router";
import store from "@/store/index";

const MyHttpServer = {};
const Url = process.env.VUE_APP_API;

MyHttpServer.install = function (Vue) {
  // 设置统一请求的服务器地址
  axios.defaults.baseURL = Url;
  // 在请求发起之前 会来到下面的回调函数
  // 添加请求拦截器
  axios.interceptors.request.use(
    function (config) {
      const token = localStorage.getItem("token") || "";
      if (token) {
        config.headers.Authorization = "JWT " + token;
      }
      return config;
    },
    function (error) {
      console.log(error);
      // 对请求错误做些什么
      // return Promise.reject(error)
    }
  );

  // 添加响应拦截器
  axios.interceptors.response.use(
    function (response) {
      // 错误消息提示
      // if (response.data.code === 4000) Message({ message: response.data.msg, type: 'error', customClass: 'messageIndex' })
      if (response.data.code === 4001 || response.data.code === 401) {
        const arr = ["username", "password"];
        const temp = {};
        arr.forEach((item) => (temp[item] = localStorage.getItem(item)));
        store.state.UserInformation = "";
        store.state.UserInfo = "";
        store.state.token = "";
        localStorage.clear();
        if (!location.href.includes("welcome")) {
          router.push("/login");
        }
        arr.forEach((item) => localStorage.setItem(item, temp[item]));
      }
      // 对响应数据做点什么
      return response.data;
    },
    function (error) {
      // 判断是否登录，没有登录401跳转登录
      // if (error.response.data.code === 401) {
      //   router.replace({
      //     path: 'login'
      //   })
      // }
      // 对响应错误做点什么
      console.log(error);
      // return Promise.reject(error)
    }
  );
  //  添加实例方法
  Vue.prototype.$http = axios;
  Vue.prototype.$baseURL = Url;
};
export default MyHttpServer;
