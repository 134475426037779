import Vue from "vue";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import MyHttpServer from "@/utils/request";
import util from "./utils/utils.js";
import area from "./utils/area.js";
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
import "swiper/dist/js/swiper.min";

// 防止多次点击
import common from "./utils/common.js";
// import 'amfe-flexible'
// import '@/utils/flexible'
import eventBus from "@/utils/eventBus";

//引入vue懒加载
import VueLazyload from "vue-lazyload";

//方法一：  没有页面加载中的图片和页面图片加载错误的图片显示

Vue.prototype.$noMultipleClicks = common.noMultipleClicks;

Vue.config.productionTip = false;
Vue.prototype.$util = util;
Vue.prototype.$area = area;
Vue.prototype.$eventBus = eventBus;

Vue.use(ElementUI, { locale });
Vue.use(MyHttpServer);
Vue.use(VueLazyload);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
