<template>
  <div id="app">
    <!-- 内容 -->
    <div :class="isRule ? 'rule-box' : 'app-box'">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
    <!-- 底部 -->
    <AppFooter v-if="!isRule" />
  </div>
</template>

<script>

import AppFooter from '@/components/app-footer'
export default {
  name: 'AppPage',
  components: { AppFooter },
  data() {
    return {
      showFooter: false,
      isRule: location.href.includes('rule')
    }
  },
  beforeCreate() {
    this.showFooter = false;
  },
  created() {
    let html = document.querySelector('html');
    let mm = document.documentElement.clientWidth
    html.style.fontSize = '12px'
    //  html.style.fontSize = document.documentElement.clientWidth/10+'px';
    // console.log(process.env.VUE_APP_URL) // 输出 localhost
  },
  methods: {
    orientationchange() {
      window.location.reload()
    }
  },
  mounted() {
    window.addEventListener('orientationchange', this.orientationchange)
    this.$nextTick(() => {
      this.showFooter = true;
    });
  }
}
</script>

<style lang="scss">
body {
  background: #1a1a1a;
  padding-right: 0px !important;
  // overflow: hidden !important;
  // -webkit-text-size-adjust: 100% !important; /* 禁用webkit内核浏览器的字体大小自动调整 */
  // text-size-adjust: 100% !important; /* 禁用其他浏览器的字体大小自动调整 */
  // transform: scale(1);
  // font-size: 12px; /* 假设你想要全局的字体大小固定为16px */
  hyphens: none;
}

.app-box {
  background: #fff;
  min-height: calc(100vh - 60px);
}

.rule-box {
  background: #fff;
  min-height: 100vh;
}

@media screen and (max-width: 768px) {
  .app-box {
    background: #FFF;
    min-height: 78vh;
  }
}

// @media screen and (min-width: 2000px) {
//   #app {
//     zoom: 1.2;
//   }
// }
// @media screen and (min-width: 3000px) {
//   #app {
//     zoom: 1.5;
//   }
// }
// @media screen and (min-width: 4000px) {
//   #app {
//     zoom: 1.8;
//   }
// }
// @media screen and (min-width: 4800px) {
//   #app {
//     zoom: 2;
//   }
// }
// @media screen and (min-width: 5000px) {
//   #app {
//     zoom: 2.1;
//   }
// }
@font-face {
  font-family: "HelveticaNeue";
  /* 指定字体类型 */
  src: url("@/assets/font/HelveticaNeue.ttc") format('truetype');
}

@font-face {
  font-family: "Futura";
  /* 指定字体类型 */
  src: url("@/assets/font/Futura.ttc") format('truetype');
}

@font-face {
  font-family: "Helvetica";
  /* 指定字体类型 */
  src: url("@/assets/font/Helvetica.ttc") format('truetype');
}

@font-face {
  font-family: "Helvetica-Bold";
  /* 指定字体类型 */
  src: url("@/assets/font/Helvetica-Bold-Font.ttf");
}

@font-face {
  font-family: "FuturaPTHeavy";
  /* 指定字体类型 */
  src: url("@/assets/font/FuturaPTHeavy.otf");
}

@font-face {
  font-family: "CondensedBold";
  /* 指定字体类型 */
  src: url("@/assets/font/DIN Condensed Bold.ttf");
}

@font-face {
  font-family: "Montserrat-Bold";
  /* 指定字体类型 */
  src: url("@/assets/font/Montserrat-Bold.otf");
}

@font-face {
  font-family: "FuturaPTDemi";
  /* 指定字体类型 */
  src: url("@/assets/font/FuturaPTDemi.otf");
}

@font-face {
  font-family: "FuturaPTbook";
  /* 指定字体类型 */
  src: url("@/assets/font/FuturaPTBook.otf");
}

@font-face {
  font-family: "FuturaPTBold";
  /* 指定字体类型 */
  src: url("@/assets/font/FuturaPTBold.otf");
}

@font-face {
  font-family: "FuturaPTMedium";
  /* 指定字体类型 */
  src: url("@/assets/font/FuturaPTMedium.otf");
}

@font-face {
  font-family: "FuturaPT";
  /* 指定字体类型 */
  src: url("@/assets/font/FuturaPTBold.otf");
}

.el-select-dropdown__wrap {
  font-family: Helvetica;
}

#app {
  font-family: "HelveticaNeue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;

  -webkit-text-size-adjust: 100%;
  /* 禁用webkit内核浏览器的字体大小自动调整 */
  text-size-adjust: 100%;
  /* 禁用其他浏览器的字体大小自动调整 */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  hyphens: none;
  word-break: keep-all;
  word-wrap: break-word;
  -webkit-overflow-scrolling: touch;
  // font-size: 14px; /* 假设你想要全局的字体大小固定为16px */
}

a {
  text-decoration: none;
}

img {
  display: block;
}

input {
  outline: none;
  border: none;
}

/* 1行 */
.oneblock {
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  vertical-align: bottom;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 2行 */
.twoblock {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

li {
  list-style: none;
}

.form-error {
  font-size: 9px;
  color: #d40023;
  display: flex;
  align-items: center;
  height: 14px;
  // margin-top: 10px;
  position: absolute;

  img {
    width: 9px;
    height: 9px;
    margin-right: 9px;
  }
}

::v-deep .el-input__inner:-moz-placeholder,
textarea:-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}

::v-deep .el-input__inner:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}

::v-deep .el-input__inner::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}

.checkbox-wrapper-40 {
  --borderColor: #fff;
  --borderWidth: 0.055em;
  margin-right: 10px;
  width: 9px;
  height: 9px;
}

.checkbox-wrapper-40 label {
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  cursor: pointer;
}

.checkbox-wrapper-40 input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  background: no-repeat;
  font-size: 16px;
  // border-radius: 5px;
  display: inline-block;
  // border: var(--borderWidth) solid var(--borderColor);
  border: 1px solid #fff;
  width: 9px;
  height: 9px;
  position: relative;
}

.checkbox-wrapper-40 input[type="checkbox"]:before,
.checkbox-wrapper-40 input[type="checkbox"]:after {
  content: "";
  position: absolute;
  background: var(--borderColor);
  width: calc(var(--borderWidth) * 3);
  height: var(--borderWidth);
  top: 50%;
  left: 16%;
  transform-origin: left center;
}

.checkbox-wrapper-40 input[type="checkbox"]:before {
  transform: rotate(45deg) translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2)) scaleX(0);
  transition: transform 200ms ease-in 200ms;
}

.checkbox-wrapper-40 input[type="checkbox"]:after {
  width: calc(var(--borderWidth) * 5);
  transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2)) scaleX(0);
  transform-origin: left center;
  transition: transform 200ms ease-in;
}

.checkbox-wrapper-40 input[type="checkbox"]:checked:before {
  transform: rotate(45deg) translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2)) scaleX(1);
  transition: transform 200ms ease-in;
}

.checkbox-wrapper-40 input[type="checkbox"]:checked:after {
  width: calc(var(--borderWidth) * 5);
  transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2)) scaleX(1);
  transition: transform 200ms ease-out 200ms;
}

// .checkbox-wrapper-40 input[type="checkbox"]:focus {
//   outline: calc(var(--borderWidth) / 2) dotted rgba(0, 0, 0, 0.25);
// }
.el-select-dropdown__empty {
  font-family: "FuturaPTMedium";
}
</style>
